<template>
	<div class="profile">
		<h1>Выход</h1>
		<p class="mt-4">Для безопасности рекомендуется выход из приложения каждый раз после завершения работы.</p>

		<v-row>
			<v-col cols="12" sm="6" md="4">
				<v-btn color="primary" block @click="logout">Выйти</v-btn>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import Config from "@/config";

export default {
	name: "logout",
	data: () => ({
		loading: false,
		error: null
	}),
	methods: {
		logout() {
			localStorage.removeItem(Config.STORAGE_AUTH_TOKEN);
			this.$router.replace({name: 'auth'});
		},
	},
	mounted() {
	}
}
</script>

<style lang="scss">

</style>